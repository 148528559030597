<template>
    <div class="noauth-page-background-color">
      <div>
        <div class="noauth-container">
            <form class="text-center"
                :action="action"
                @submit.prevent="submit">
                <img
                    src="@/assets/images/logo_patrimial.png"
                    class="logo-ymanci mb-4"
                />
                <p class="font-weight-light mb-5">{{Welcome_Msg}}</p>
                <slot></slot>
            </form> 
        </div>
      </div>
    </div>
</template>

<script>
export default {
    props: {
        action: {
            type: String,
            default: ''
        },
        submit: Function,
        Welcome_Msg: String
    }
}
</script>

<style scoped lang="scss">
@import "../assets/css/components/noauth.scss";
</style>
